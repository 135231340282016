
import i18n from "i18next";
import classes from './TextView.module.css';




function TextView({type}) {
  const stepTitle =i18n.t(type+".title");


  
  return (
    <div className={classes.root}>      
      <div className={classes.header}>
        <div className={classes.dots + ' ' + classes.finished}></div>
        <h1 className={"mixed"} dangerouslySetInnerHTML={{__html:stepTitle}}></h1>
      </div>
      <div className={classes.content}>
        {i18n.t(type+".text")}
      </div>
      <div className={classes.footer}></div>
    </div>
  );
}

export default TextView;
