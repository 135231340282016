
import i18n from "i18next";
import classes from './Header.module.css';
import { useTranslation, initReactI18next } from "react-i18next";
import { SetLanguage } from "../i18n";

import menuImg from "./../images/hamburgerMenu.svg";
import logotmp from "./../images/chioAachenCO2CiSmall.png";
import co2 from "./../images/CO2Great.svg";
import logoMain_de from "./../images/chioAachenCiGreat.svg";
import logoMain_en from "./../images/chioAachenCiGreat-en.svg";
import Drawer from "../view_drawer/Drawer";
import { useState } from "react";

function Header() {
  const { t } = useTranslation();
  const [drawerOpened, setDrawerOpened] = useState(false)
  const toggleIntro = ()=>{
    if(document.body.classList.contains("intro"))
      document.body.classList.remove("intro")
    else
      document.body.classList.add("intro")
  }

  const toggleDrawer = ()=>{
    setDrawerOpened(!drawerOpened)
  }

  const closeDrawer = ()=>{
    setDrawerOpened(false)
  }
  const otherLang = i18n.language==="de"?"en":"de";

  return (
    <div className={classes.root}>
      <div className={"btn_circle"} onClick={()=>{setTimeout(()=>SetLanguage(otherLang), 100)}}>{t(otherLang)}</div>
      <div className={classes.logo}>
        <img className={classes.logoMain} src={logoMain_de} alt="CHIO Logo" />
        <img className={classes.logoCo} src={co2} alt="CO2" />
      </div>
      <Drawer opened={drawerOpened} closeCallback={closeDrawer}></Drawer>
      <div className={"btn_circle " + classes.menuBtn} onClick={toggleDrawer}><img src={menuImg} /></div>      
    </div>
  );
}

export default Header;
