function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

let db = null;
let _uuid = uuid();

function sendData(data){
  
  let d = [];
  for(let i in data?.steps){
    d.push({id:data?.steps[i], val:data.results[data?.steps[i]]?.id?data.results[data?.steps[i]].id:data.results[data?.steps[i]]});
  }
  
  let server = 'https://www.co2-chioaachen.de';
  server = 'https://chio.4315.de';
  let xhr = new XMLHttpRequest();
  let url = (document.location.hostname.indexOf("localhost")>-1 || document.location.hostname.indexOf("127.0.0.1")>-1?server:'')+"/send.php";
  
 
  
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
          var json = JSON.parse(xhr.responseText);
          //console.log(json);
      }
  };
  var data = JSON.stringify({ts:new Date().getTime(), data:d, uuid:_uuid, finished:data.finished});
  xhr.send(data);
}
export function ParseDB(raw){
  return new DB(raw);
}

export class DB{
  constructor(raw){
    this.raw = raw;

    this.stepId = '';
    DB.state={steps:[], results:{}};
    this.reset();    
    
  }

  reset(){
    _uuid = uuid();
    this.steps=[];
    for(let i in this.raw.steps){
      let step = new Step(this.raw.steps[i], this);
      this.steps.push(step);
    }
    this.stepId = this.steps[0].id;

    DB.state = {
      results:{'city':{name:"otown", location:{lat:0, lng:0}}},
      steps:["city"]
      
    };

    DB.state = {
      "results": {
        "city": {
          "name": "otown",
          "location": {
            "lat": 0,
            "lng": 0
          }
        },
        "travel_type": {
          "id": "travel_type_car"
        },
        "car_size": {
          "id": "car_size_l"
        },
        "car_age": {
          "id": "car_age_old"
        },
        "car_engine": {
          "id": "car_engine_diesel"
        }
      },
      "steps": [
        "city",
        "travel_type",
        "car_size",
        "car_age",
        "car_engine",
        "car_persons"
      ]
    }


    DB.state = {
      results:{'city':{name:"otown", location:{lat:0, lng:0}}},
      steps:["city"]
      
    };
    
    this.stepId = DB.state.steps[DB.state.steps.length-1]
    

  }

  setNextStep(){
   
    DB.state.finished = false;
    if(!this.isActualStepFinished())
      return false;
    let step = this.findNextStep(this.stepId);
    
    if(step){
      let inserted=false;
      for(let i=0; i<DB.state.steps.length;i++){
        if(DB.state.steps[i] === this.stepId  && DB.state.steps.length>i+1){
          
          if(DB.state.steps[i+1] == step.id){
            inserted=true;
          }else{
            console.log("splice")
            DB.state.steps.splice(i+1, DB.state.steps.length-i);
            //step.value = null;
          }
        }
      }
      if(!inserted)
        DB.state.steps.push(step.id);
      this.stepId = step.id;
    }else{      
      DB.state.finished = true;      
    }

    sendData(DB.state);
    //let count = DB.state.steps.length;

  }
  setPrevStep(){
    DB.state.finished = false;
    for(let i=0; i<DB.state.steps.length;i++){
      if(DB.state.steps[i] === this.stepId && i>0)
        this.stepId = DB.state.steps[i-1];
    }
  }
  findEntry(id){
    for(let i in this.steps){
      if(this.steps[i].Find(id))
        return this.steps[i].Find(id);
    }
  }

  findNextStep(id){
    let entry = this.findEntry(id);
    let nEntry = entry.GetFollowingStep();
    if(nEntry?.MatchPremise())
      return entry.GetFollowingStep();

    for(let loops=0;loops<5;loops++){
      nEntry = nEntry?.GetFollowingStep();

      if(nEntry && nEntry.MatchPremise && nEntry.MatchPremise())
        return entry.GetFollowingStep();
    }
    
    return null;
  }

 

  setResult(stepId, result){
    DB.state.results[stepId] = result;

    for(let i=0; i<DB.state.steps.length;i++){
      if(DB.state.steps[i] === stepId){
       if(JSON.stringify(DB.state.steps[i].value) != JSON.stringify(result) && DB.state.steps.length>i+1){          
          DB.state.steps.splice(i+1,1);
        }
      }
    }

   
    
  }

  getActualStep(){
    let entry = this.findEntry(this.stepId);
    return entry;
  }
  
  getActualStepId(){
    return this.stepId;
  }
  
  isLastStep(){
    return false;
  }

  isActualStepFinished(){
    return DB.getResult(this.stepId)!=null && DB.state.steps.indexOf(this.stepId)>-1;
  }

  static isFinished(){
    return DB.state.finished === true;
  }
  static getResult(id){
    return DB.state.results[id];
  }
}


class Step{
  constructor(raw, parent){
    this.__type = 'Step';
    this.id=raw.id;
    this.parent = parent;
    this.title = raw.title;
    this.options = [];
    this.premise = [{"key":"stay_days","operator":"not", "value":"stay_day_1"}]
    this.premise = raw.premise?raw.premise:[];
    for(let i in raw.entries){
      let option = new StepOption(raw.entries[i], this);
      this.options.push(option);
    }

    this.steps = [];
    for(let i in raw.steps){
      let step = new Step(raw.steps[i], this);
      this.steps.push(step);
    }
  }

  MatchPremise(){
    for(let i in this.premise){
      let val = DB.state.results[this.premise[i].key];
      if(this.premise[i].operator === 'not' && val?.id == this.premise[i].value){
        return false;
      }
      else if(this.premise[i].operator === 'is' && val != this.premise[i].value)
        return false;
    }

    return true;
  }
  GetMainId(){
    if(this.parent.GetMainId == null){
      return this.parent.steps.indexOf(this);
    }else{
      return this.parent.GetMainId();      
    }
  }

  Find(id){
    if(this.id === id){
      return this;
    }
    for(let i in this.options){
      if(this.options[i].Find(id))
        return this.options[i].Find(id);
    }
    for(let i in this.steps){
      if(this.steps[i].Find(id))
        return this.steps[i].Find(id);
    }
  }

  GetFollowingStep(){

    if(this.options.length>0)
    {
      const resultId = DB.getResult(this.id)?(DB.getResult(this.id)).id:null;
      //const resultId = id;
      if(resultId){
        const selectedOption = this.options.find((stepOption=>stepOption.id === resultId));
        
        if(selectedOption && selectedOption.steps.length>0)
          return selectedOption.steps[0];
      }else
        return this;        
    }
   
    if(this.parent.steps){
      let i = this.parent.steps.indexOf(this);
      
      if(i<this.parent.steps.length-1){
        return this.parent.steps[i+1]
      }
      else{
        if(this.parent == null || this.parent.GetFollowingStep == null)//finished
          return null;
        const nextStep = this.parent.GetFollowingStep();
        
        if(nextStep?.steps?.length>0){
        //  console.log(nextStep, nextStep.steps[0])
          return nextStep.steps[0];
        }

  
        return nextStep;
      }
    }
    console.log("sdf");
    return this.parent.GetFollowingStep();
  }
}

class StepOption{
  constructor(raw, parent){
    
    this.__type = 'StepOption'
    this.id = raw.id;
    this.title = raw.title;
    this.parent = parent;
    this.icon = raw.icon;
    this.iconText = raw.icon_text;
    this.steps = [];
    for(let i in raw.steps){
      let step = new Step(raw.steps[i], this);
      this.steps.push(step);
    }
  }

  Find(id){
    for(let i in this.steps){
      if(this.steps[i].Find(id))
        return this.steps[i].Find(id);
    }
  }

  GetMainId(){
    if(this.parent.GetMainId == null){
      return this.parent.steps.indexOf(this);
    }else{
      return this.parent.GetMainId();      
    }
  }
  GetFollowingStep(){

    if(this.parent.parent.steps){
      let i = this.parent.parent.steps.indexOf(this.parent);
      
      if(i<this.parent.parent.steps.length-1)
        return this.parent.parent.steps[i+1];
      else{
        console.log("sdf");
        return this.parent.GetFollowingStep();
      }
    }
    console.log("sd2f");
    return this.parent.GetFollowingStep();
  }
}