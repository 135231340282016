import i18n from "i18next";
import { initReactI18next } from "react-i18next";

var userLang = navigator.language || navigator.userLanguage; 

let lang = userLang.split("-")[0].toLowerCase();
if(localStorage.getItem("language"))
  lang = localStorage.getItem("language");

if(lang != "de")
  lang = "en";

i18n
  .use(initReactI18next)
  .init({
    debug:false,
    resources: {
      de: {
        translation: {
          "de":"DE",
          "en":"EN",
          "Welcome to React":"Willkommen",
          "intro_start_btn":"<b>CO<sub>2</sub> Fußabdruck</b> Rechner starten",
          "city":{
            "placeholder":"Eingabe Deiner Stadt..."
          },
          "menu":{
            "footprint":"CO<sub>2</sub> Fußabdruck",
            "information":"Information",
            "languageSelect":"Sprachauswahl",
            "contact":"Kontakt",
            "imprint":"Impressum"

          },
          "finish":{
            "title":"<b>Dein persönlicher CO<sub>2</sub> Fußabdruck</b><br/>zum CHIO-Gelände.",
            "label_1":"Dein persönlicher CO<sub>2</sub> Fußabdruck",
            "text_1":"<b>{{amount}}</b> Tonnen CO<sub>2</sub>",
            "label_2":"Durchschnittlicher CO<sub>2</sub> Fußabdruck der Besucher",
            "text_2":"<b>{{amount}}</b> Tonnen CO<sub>2</sub>",
            "text_3":`Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et usto duo dolores et ea rebum.

            Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.`
          },
          "imprint":{
            "title":"<b>Impressum</b>",
            "text":`Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod empor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At
            vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet,

            consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo
            duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem

            ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr,DB.state sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,DB.state sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. StetDB.state clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.DB.state Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat,DB.state vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odioDB.state dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugaitDB.state nulla facilisi. Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummyDB.state nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.DB.state Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortisDB.state nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolorDB.state in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiatDB.state nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesentDB.state luptatum zzril delenit augue duis dolore te feugait nulla facilisi.DB.state Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet domingDB.state id quod mazim placerat facer possim assum. Lorem ipsum dolor sit amet, consectetuerDB.state adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet doloreDB.state magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerciDB.state tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.`
          },
          "contact":{
            "title":"<b>Kontakt</b>",
            "text":`tbd`
          },
          "information":{
            "title":"<b>Information</b>",
            "text":`tbd`
          }
        },
        
      },
      en: {
        translation: {
          "de":"DE",
          "en":"EN",
          "Welcome to React":"Welcome",
          "intro_start_btn":"<b>CO<sub>2</sub> Fußabdruck</b> Rechner starten",
          "city":{
            "placeholder":"Eingabe Deiner Stadt..."
          }
        },
      },
      
    },
    lng: lang,
    fallbackLng: "en",
  });

export default i18n;

export function SetLanguage(lang){
  localStorage.setItem("language", lang);
  i18n.changeLanguage(lang);
}