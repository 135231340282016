
import i18n from "i18next";
import classes from './CalculatorView.module.css';
import cclasses from './CalculatorViewContent.module.css';
import fclasses  from './CalculatorFinishView.module.css';
import { useTranslation, initReactI18next } from "react-i18next";
import HtmlElement from "../HtmlElement";
import { Link, useNavigate  } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import CalculatorTile from "./CalculatorTile";
import { AppContext } from "../_contexts/AppContext";
import MapsView from "../view_maps/MapsView";
import { DB } from "../DB";

import finishedImg from './../images/finish.jpg';

function CalculatorView({step_id}) {
  const db = useContext(AppContext).db;
  //console.log(db.getActualStepId())

  const containerRef = useRef();
  let navigate = useNavigate();

  const [render, setRender]=useState(0);
  const { t } = useTranslation();
  const mapsDone = (name, location)=>{   
    db.setResult(db.getActualStepId(), {name:name, location:location})
    setRender(new Date().getTime())
  }

  const tileSelected = (id)=>{
    
    db.setResult(db.getActualStepId(), {id:id})
    db.setNextStep();
    if(!DB.isFinished())
      navigate("/calculator/"+db.getActualStepId())
    else
      navigate("/calculator/done")
    //setRender(new Date().getTime());
  }
  const prevStep=()=>{
    db.setPrevStep();
    console.log(db.getActualStepId())
    navigate("/calculator"+("/"+db.getActualStepId()))
    //setRender(new Date().getTime());
  }
  const nextStep=()=>{
    if(DB.isFinished())
    {
      db.reset();
      navigate("/")
    }else{
      db.setNextStep()
      setRender(new Date().getTime());
    }
    /*if(step>=7)
      return;
    setStep(step+1)*/
  }

  const stepData = db.getActualStep();
 // console.log(stepData)
  //console.log(stepData.GetMainId())
  const stepResult = DB.getResult(stepData?.id);
  //console.log(stepData, stepResult)
  
  const stepTitle = DB.isFinished()?i18n.t("finish.title"):stepData?.title[i18n.language];

  let activeDot = stepData.GetMainId();
  if(DB.isFinished())
    activeDot = 3;

  const entries = stepData && stepData.options?stepData.options:[];
 
  let columns = 3;
  if(containerRef.current){
    
    const r = containerRef.current.getBoundingClientRect();
    const ratio = r.width / r.height;
   
    //if(r.width<801)
    {
      const minw = 120;
      //if(ratio<.3)
      //  columns = 1
      if(ratio<.5)
        columns = 2
      else if(ratio<1)
        columns = 3
      else if(ratio<1.5)
        columns = 4
      else if(ratio<1.8)
        columns = 5
      else if(ratio<2.5)
        columns = 6
      
    }
  }
  

  
  return (
    <div className={classes.root}>      
      <div className={classes.header}>
        <div className={classes.dots+' ' +(activeDot>2?classes.finished:'')}>
          <div className={activeDot===0?classes.active:''}></div>
          <div className={activeDot===1?classes.active:''}></div>
          <div className={activeDot===2?classes.active:''}></div>
        </div>
        <h1 className={"mixed"} dangerouslySetInnerHTML={{__html:stepTitle}}></h1>
      </div>
      <div className={classes.content + ' ' +cclasses.root}  ref={containerRef}>
        
        <MapsView doneCallback={mapsDone} hidden={stepData.id !== 'city'} closeCallback={nextStep}></MapsView>
        {!DB.isFinished() &&  stepData.id !== 'city' &&
        <>
          <div className={cclasses.bg}></div>

          <div className={cclasses.container} style={{'--columns':columns}}>
            {
            entries.map((entry=>{
              return(
                <CalculatorTile key={entry.id} entry={entry} onClick={()=>tileSelected(entry.id)} active={entry.id === stepResult?.id}></CalculatorTile>              
              )}  
              ))
            }
          </div>
        </>
        }
        {DB.isFinished() &&  stepData.id !== 'city' &&
        <>          
          <div className={cclasses.container + ' '+fclasses.root}>
            <img src={finishedImg} className={fclasses.img} alt="finished"></img>
            <span className={fclasses.label}>{i18n.t("finish.label_1")}</span>
            <span className={fclasses.value + ' '+fclasses.center} dangerouslySetInnerHTML={{__html:i18n.t("finish.text_1",{amount:'7,85'})}}></span>
            <span className={fclasses.label}>{i18n.t("finish.label_2")}</span>
            <span className={fclasses.value} dangerouslySetInnerHTML={{__html:i18n.t("finish.text_2",{amount:'4,27'})}}></span>
            <p className={fclasses.text}>{i18n.t("finish.text_3")}</p>
          </div>
        </>
        }
      </div>
      <div className={classes.footer}>
        <div className={classes.footerBtn + ' '+(db.getActualStepId()==='city'?classes.hidden:'')} onClick={prevStep}></div>
        {db.isLastStep() && <div>LOGO</div>}
        <div></div>
        <div className={classes.footerBtn + ' '+(db.isLastStep() || !db.isActualStepFinished()?classes.hidden:'')} onClick={nextStep}></div>
      </div>
    </div>
  );
}

export default CalculatorView;
