
import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import { ParseDB } from "./../DB";

const AppContext = createContext();

const AppContextProvider = ({children }) => {
  const [db, setDB] = useState(null);

  useEffect(() => {
      fetch('/db.json').then(response => {
        return response.json();
      }).then(data => {
        const _db = ParseDB(data);
        setDB(_db)      
      }).catch(err => {
        // Do something for an error here
        console.error("Error Reading dadb.jsonta " + err);
      });
  },[]);

 

  if(!db || Object.keys(db).length === 0)
    return(<AppContext.Provider value={{db}}></AppContext.Provider>)
  
  //console.log(db)


  return (
    <AppContext.Provider value={{db}}>
      {children}
    </AppContext.Provider>
  );
};

const States = {
  'kiosk':'kiosk',
  'admin':'admin',
  'admin_login':'admin_login'
}

export { AppContext, AppContextProvider, States };
