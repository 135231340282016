
import classes from './IntroView.module.css';
import { useTranslation, initReactI18next } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


function IntroView({visible}) {
  const [state, setState] = useState(0);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const startClicked=()=>{
    setState(1);
    setTimeout(()=>{navigate("/calculator")}, 1000);
  }

  return (
    <div className={classes.root + " " + (visible?classes.visible:classes.hidden) + ' ' +classes["_"+state]}>
      <div className={"mixed " + classes.startBtn} to="/calculator" dangerouslySetInnerHTML={{__html:t("intro_start_btn")}} onClick={startClicked}></div>
      <div className={classes.semi}>
        <div className={classes.bgImg}></div>
      </div>
      <div className={classes.bgImg + ' '+classes.masked}></div>
      <div className={classes.co2}></div>
    </div>
  );
}

export default IntroView;
