
import i18n from "i18next";
import classes from './MapsView.module.css';

import { useTranslation, initReactI18next } from "react-i18next";
import HtmlElement from "../HtmlElement";
import { Link } from "react-router-dom";
import { useContext, useState, useRef } from "react";

import { AppContext } from "../_contexts/AppContext";
import { Loader } from "@googlemaps/js-api-loader";
import { useEffect } from "react";
import searchIcon from "./../images/searchIcon.svg";
/*<script async
src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=initMap">
</script>
*/
function MapsView({doneCallback, hidden, closeCallback}) {
  const mapRef = useRef();
  const mapServiceRef = useRef();
  const mapApiRef = useRef();
  const db = useContext(AppContext).db;
  //console.log(db.getActualStepId())

  
  const { t } = useTranslation();
  const stepData = db.getActualStep();

  const textChanged=(e)=>{
   

    if(e.target.value.length>3){
      mapApiRef.current.setCenter({lat: 0, lng: 0});
      const request = {
        query: e.target.value,
        fields: ["name", "geometry"],
      };
    
      mapServiceRef.current.findPlaceFromQuery(
        request,(results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
            if(results[0].geometry && results[0].geometry.location){
              if(doneCallback)
                doneCallback(results[0].name, {lat:results[0].geometry.location.lat(), lng:results[0].geometry.location.lng()})
              mapApiRef.current.setCenter(results[0].geometry.location);
            }
          }
        }
      );

    }


  }
  useEffect(()=>{
    const loader = new Loader({
      apiKey: "AIzaSyAm470Pkbvzri6doZf1AepLIuZIopldC78",
      version: '3.49',
      libraries:['places']
    });
    
    loader.load().then(() => {
      let map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 50.79585844458857, lng: 6.094524183411447 },
        zoom: 13,
        disableDefaultUI:true,
        styles:[
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [
              { visibility: "off" }
            ]
          }
        ]
      });
      let service = new window.google.maps.places.PlacesService(map);
      mapServiceRef.current = service;
      mapApiRef.current = map;
    });
  },[]);
  
  
  return (
    <div className={classes.root + (hidden?' '+classes.hidden:'')}>      
      <div className={classes.header}>
        <span>
          <input type="text" placeholder={i18n.t("city.placeholder")} onInput={textChanged} onKeyDown={(e)=>{console.log(e.key);if(e.key==="Enter"){e.target.blur();console.log("done")}}}></input>
          <img src={searchIcon} alt="search"></img>
        </span>
      </div>
      <div className={classes.content}>
       <div ref={mapRef} className={classes.map}></div>
      </div>
     
    </div>
  );
}

export default MapsView;
