
import i18n from "i18next";
import classes from './Drawer.module.css';
import { useTranslation, initReactI18next } from "react-i18next";
import { SetLanguage } from "../i18n";

import menuImg from "./../images/hamburgerMenu.svg";
import logotmp from "./../images/chioAachenCO2CiSmall.png";
import co2 from "./../images/CO2Great.svg";
import logoMain_de from "./../images/chioAachenCO2CiSmall-white.png";
import logoMain_en from "./../images/chioAachenCO2CiSmall-white-en.png";
import { useNavigate } from "react-router-dom";

function Drawer({opened, closeCallback }) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  
  return (
    <div className={classes.root + ' ' + (opened?classes.opened:classes.closed)}>        
      <img className={classes.logo} src={logoMain_de} alt="logo"></img>
      <div className={classes.container}>
        <div onClick={()=>{closeCallback();navigate("/calculator")}} className={classes.ia} dangerouslySetInnerHTML={{__html:t("menu.footprint")}}></div>
        <div onClick={()=>{closeCallback();navigate("/information")}} className={classes.ia} dangerouslySetInnerHTML={{__html:t("menu.information")}}></div>
        <div className={classes.language}>
          <span>{t("menu.languageSelect")}</span>
          <div>
            <div className={"btn_circle"+(i18n.language==="de"?' active':'')} onClick={()=>{setTimeout(()=>SetLanguage("de"), 100)}}>{t("de")}</div>
            <div className={"btn_circle"+(i18n.language==="en"?' active':'')} onClick={()=>{setTimeout(()=>SetLanguage("en"), 100)}}>{t("en")}</div>
          </div>
        </div>
        <div onClick={()=>{closeCallback();navigate("/contact")}} className={classes.ia} dangerouslySetInnerHTML={{__html:t("menu.contact")}}></div>
        <div onClick={()=>{closeCallback();navigate("/imprint")}} className={classes.ia} dangerouslySetInnerHTML={{__html:t("menu.imprint")}}></div>
      </div>
    </div>
  );
}

export default Drawer;
